import { Box, Button, Center, Text } from "@chakra-ui/react";
import "./screen.css";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useRef, useState } from "react";
import particlesConfig from "./particlesConfig.json";
import { loadFull } from "tsparticles";
import { loadSlim } from "@tsparticles/slim";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import {
  IoMdCheckmarkCircle,
  IoMdCheckmarkCircleOutline,
  IoMdSettings,
} from "react-icons/io";
import { Link } from "react-router-dom";

const Screen1 = (props) => {
  const [init, setInit] = useState(false);
  const slideInRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Optional: stop observing after the first reveal
          }
        });
      },
      { threshold: 0.1 } // Adjust this value to control when the image slides in
    );

    if (slideInRef.current) {
      observer.observe(slideInRef.current);
    }

    return () => {
      if (slideInRef.current) {
        observer.unobserve(slideInRef.current);
      }
    };
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />

      <div className="content">
        <div className="row" style={{ width: "100%" }}>
          <Text fontSize="xxx-large" textAlign="center">
            Bridging Dreams and Opportunities for jobs
          </Text>
          <div className="col-md-6">
            <List spacing={3}>
              <ListItem>
                <ListIcon as={IoMdCheckmarkCircleOutline} color="green.500" />
                World's largest freelance marketplace
              </ListItem>
              <ListItem>
                <ListIcon as={IoMdCheckmarkCircleOutline} color="green.500" />
                Any job you can possibly think of
              </ListItem>
              <ListItem>
                <ListIcon as={IoMdCheckmarkCircleOutline} color="green.500" />
                Save up to 90% & get quotes for free
              </ListItem>
              <ListItem>
                <ListIcon as={IoMdCheckmarkCircleOutline} color="green.500" />
                Pay only when you're 100% happy
              </ListItem>
            </List>
            <Button
              colorScheme="pink"
              marginRight={1}
              fontSize="x-large"
              height="60px"
              marginBottom={3}
              as={Link}
              to="/contact-us"
            >
              Contact US
            </Button>
            <Button
              colorScheme="white"
              variant="outline"
              height="60px"
              marginBottom={3}
            >
              Earn Money
            </Button>
          </div>
          <div className="col-md-6 slide-in-container" ref={slideInRef}>
            <Center>
              <img
                className={`slide-in-image ${isVisible ? "slide-in" : ""}`}
                src="assests/Opportunities.jpg"
                alt="Slide In Example"
              />
            </Center>
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen1;
