import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const Howwework = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to SHARDA ASSOCIATES
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          About US
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          SHARDA ASSOCIATES, we are passionate about helping individuals
          rediscover their career potential. Whether you're seeking a fresh
          start, transitioning to a new industry, or aiming to advance your
          current career, we are here to support you every step of the way.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Who We Are
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          SHARDA ASSOCIATES is a leading HR consultancy firm dedicated to
          connecting talented professionals with exceptional career
          opportunities. Our team of experienced HR experts and consultants
          brings together a wealth of knowledge and industry expertise to
          provide tailored career solutions for our clients.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Our Mission
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our mission is to empower individuals to achieve their career
          aspirations by providing personalized guidance, expert advice, and a
          comprehensive range of HR services. We believe that everyone deserves
          the chance to pursue a fulfilling career, and we are committed to
          making that a reality for our clients.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          What We Do
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Career Counseling and Guidance: We offer one-on-one career counseling
          sessions to help you identify your strengths, interests, and career
          goals. Our experts provide actionable insights and advice to guide you
          on your career journey.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Job Placement Services: Our extensive network of employers and
          industry contacts allows us to match job seekers with the right
          opportunities. We work tirelessly to ensure that our candidates find
          positions that align with their skills and aspirations.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Resume and Interview Preparation: We help you create compelling
          resumes and prepare for interviews with confidence. Our team provides
          constructive feedback and coaching to enhance your presentation and
          interview skills.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          HR Consulting for Businesses: We offer comprehensive HR consulting
          services to businesses looking to optimize their HR processes, from
          recruitment and onboarding to performance management and employee
          development.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Why Choose Us
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Personalized Approach: We understand that every individual and
          business is unique. Our services are tailored to meet the specific
          needs of each client, ensuring the best possible outcomes
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Experienced Team
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our team comprises seasoned HR professionals with a deep understanding
          of various industries. We bring a wealth of experience and expertise
          to every client engagement.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Commitment to Excellence
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We are dedicated to delivering exceptional service and results. Our
          commitment to excellence drives everything we do, from client
          interactions to the solutions we provide.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Join Us Today
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          At Jobs Again HR & Consultants Private Limited, your career growth and
          satisfaction are our top priorities. Whether you're a job seeker
          looking for new opportunities or a business in need of expert HR
          support, we are here to help you succeed.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Explore our services, connect with our team, and take the next step in
          your career journey with Jobs Again HR & Consultants Private Limited.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Contact Us
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Feel free to reach out to us with any questions or to learn more about
          how we can assist you. We look forward to partnering with you on your
          path to success.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          This content is designed to provide a comprehensive overview of your
          company's services, mission, and unique value proposition. Adjust any
          specific details to better match your company's offerings and values.
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default Howwework;
