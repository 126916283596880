import React, { useEffect, useState } from "react";
import "./../allusers/Allusers.css";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import particlesConfig from "./../Layout/particlesConfig.json";
import {
  Box,
  Button,
  Center,
  Image,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../Header/Navbar";
import axios from "axios";
import * as mod from "./../../url";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa";
import { BsCloudDownload } from "react-icons/bs";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FcDisclaimer } from "react-icons/fc";

const GetProjectDetails = (props) => {
  const [init, setInit] = useState(false);
  const [data, setData] = useState([]);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedItemId, setSelectedItemId] = useState("");

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${mod.api_url}/freelancer/get_freelancers`
      );
      setData(response.data);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs backend issue",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate the indices of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const renderTableRows = () => {
    return data.map((item) => (
      <Tr key={item._id}>
        <Td>{item.projects?.prjectname}</Td>
        <Td>{item.name}</Td>
        <Td>{item.email}</Td>
        <Td>{item.mobile}</Td>
        <Td>{item.duration}</Td>
        <Td>{item.country}</Td>
        <Td>{item.createdAt.slice(0, 10)}</Td>
        <Td>{item.desc.slice(0, 100)}...</Td>
      </Tr>
    ));
  };

  return (
    <>
      <Particles
        id={props.id}
        init={(container) => console.log(container)}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>

      <Center>
        <div className="Allusers">
          <Box width="100%">
            <Text fontSize="xx-large" fontWeight="600" color="hotpink">
              All Freelancer Proposal Data
            </Text>
          </Box>
          {data && data.length > 0 ? (
            <>
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th color="white" fontSize="large">
                        Project Name
                      </Th>
                      <Th color="white" fontSize="large">
                        Name
                      </Th>
                      <Th color="white" fontSize="large">
                        Email
                      </Th>
                      <Th color="white" fontSize="large">
                        Mobile
                      </Th>
                      <Th color="white" fontSize="large">
                        Duration
                      </Th>
                      <Th color="white" fontSize="large">
                        Country
                      </Th>
                      <Th color="white" fontSize="large">
                        Date
                      </Th>
                      <Th color="white" fontSize="large">
                        Discription
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>{renderTableRows()}</Tbody>
                </Table>
              </TableContainer>

              <Box display="flex" justifyContent="center" mt={4}>
                {Array.from({ length: totalPages }, (_, index) => (
                  <Button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    colorScheme="pink"
                    mx={1}
                    marginBottom={3}
                    disabled={currentPage === index + 1}
                  >
                    {index + 1}
                  </Button>
                ))}
              </Box>
            </>
          ) : (
            <Box width="100%" display="block">
              <Text>No data available</Text>
              <Center>
                {" "}
                <FcDisclaimer style={{ width: "60px", height: "60px" }} />
              </Center>
            </Box>
          )}
        </div>
      </Center>
    </>
  );
};

export default GetProjectDetails;
