import { Text } from "@chakra-ui/react";
import React from "react";
import "./screen.css";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useRef, useState } from "react";
import particlesConfig from "./particlesConfig.json";
import { loadFull } from "tsparticles";
import { loadSlim } from "@tsparticles/slim";

const Video = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <div className="video">
        <video
          autoPlay
          loop
          muted
          className="video-box"
          style={{ width: "100%" }}
        >
          <source src="assests/video-job-portal.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default Video;
