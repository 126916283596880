import React, { useContext, useState } from "react";
import "./Navbar.css";
import {
  Box,
  Button,
  Center,
  MenuItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { SelectedItemContext } from "../SelectedItemContext";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("adminInfo") !== null
  );
  const [skilljobs, setSkilljobs] = useState([]);
  const [countryjobs, setCountryjobs] = useState([]);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { setSelectedItem } = useContext(SelectedItemContext);
  const {
    isOpen: iscountryOpen,
    onOpen: oncountryOpen,
    onClose: oncountryClose,
  } = useDisclosure();
  const {
    isOpen: isSkillOpen,
    onOpen: onSkillOpen,
    onClose: onSkillClose,
  } = useDisclosure();
  const {
    isOpen: isdatabaseOpen,
    onOpen: ondatabaseOpen,
    onClose: ondatabaseClose,
  } = useDisclosure();
  const isAdmin = localStorage.getItem("adminInfo");

  const logoutHandler = () => {
    localStorage.removeItem("adminInfo");
    setIsLoggedIn(false); // Update state to trigger re-render
    window.location.reload(); // Reload the page
  };

  const GetCountryJobs = async () => {
    oncountryOpen();
    try {
      const response = await axios.get(`${mod.api_url}/files/countries`);
      setCountryjobs(response.data);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const GetSkillJobs = async () => {
    onSkillOpen();
    try {
      const response = await axios.get(`${mod.api_url}/files/skils`);
      setSkilljobs(response.data);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleCountryClick = async (item) => {
    oncountryClose();
    setSelectedItem(item);
    navigate(`/get-jobs-by-country`, { state: { country: item } });
  };

  const handleSkillClick = async (item) => {
    onSkillClose();
    setSelectedItem(item);
    navigate(`/get-jobs-by-id-filter`, { state: { skill: item } });
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-tertiary"
        style={{ background: "black" }}
      >
        <div className="container-fluid">
          <span className="navbar-brand">
            <Link to="/">
              <img
                src="assests/Jobs-Again-Hr-&-Consultants-logo.png"
                alt="Logo"
              />
            </Link>
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ color: "white" }}
          >
            <TiThMenu />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <span className="navbar-nav me-auto mb-1 mb-lg-0">
              <span className="nav-item">
                <Menu>
                  <MenuButton
                    colorScheme="pink"
                    as={Button}
                    rightIcon={<IoMdArrowDropdown />}
                  >
                    Find Jobs
                  </MenuButton>
                  <MenuList marginTop={7}>
                    <Box>
                      <MenuItem
                        onClick={GetSkillJobs}
                        marginBottom={2}
                        color="black"
                      >
                        Jobs By Skills
                      </MenuItem>
                      <Modal isOpen={isSkillOpen} onClose={onSkillClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader textAlign="center">
                            Jobs By Skills
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {skilljobs &&
                              skilljobs.map((item) => (
                                <div key={item._id}>
                                  <Center>
                                    <Button
                                      width="95%"
                                      colorScheme="pink"
                                      color="white"
                                      marginBottom={1}
                                      onClick={() => handleSkillClick(item)}
                                    >
                                      {item}
                                    </Button>
                                  </Center>
                                </div>
                              ))}
                          </ModalBody>
                          {/* <ModalFooter>
                            <Button
                              width="40%"
                              colorScheme="blue"
                              mr={3}
                              onClick={onSkillClose}
                            >
                              Close
                            </Button>
                          </ModalFooter> */}
                        </ModalContent>
                      </Modal>
                    </Box>
                    <Box>
                      <MenuItem
                        onClick={GetCountryJobs}
                        marginBottom={2}
                        color="black"
                      >
                        Jobs By Country
                      </MenuItem>
                      <Modal isOpen={iscountryOpen} onClose={oncountryClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader textAlign="center">
                            Jobs By Country
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {countryjobs &&
                              countryjobs.map((item) => (
                                <div key={item._id}>
                                  <Center>
                                    <Button
                                      width="95%"
                                      colorScheme="pink"
                                      color="white"
                                      marginBottom={1}
                                      onClick={() => handleCountryClick(item)}
                                    >
                                      {item}
                                    </Button>
                                  </Center>
                                </div>
                              ))}
                          </ModalBody>
                          {/* <ModalFooter>
                            <Button
                              width="40%"
                              colorScheme="blue"
                              mr={3}
                              onClick={oncountryClose}
                            >
                              Close
                            </Button>
                          </ModalFooter> */}
                        </ModalContent>
                      </Modal>
                    </Box>
                    <Box>
                      <MenuItem as={Link} to="/get-jobs" color="black">
                        All Jobs
                      </MenuItem>
                    </Box>
                    <Box>
                      <MenuItem
                        as={Link}
                        to="/apply-random-job-by-user"
                        color="black"
                      >
                        Apply Any Jobs
                      </MenuItem>
                    </Box>
                  </MenuList>
                </Menu>
              </span>

              <span className="nav-item">
                <Button
                  colorScheme="pink"
                  as={Link}
                  to="/get-all-freelancer-projects"
                  rightIcon={<IoMdArrowDropdown />}
                >
                  Freelancer
                </Button>
              </span>

              <span className="nav-item">
                <Menu>
                  <Box>
                    {isAdmin ? (
                      <MenuButton
                        colorScheme="pink"
                        as={Button}
                        rightIcon={<IoMdArrowDropdown />}
                      >
                        Admin Panel
                      </MenuButton>
                    ) : null}
                    <MenuList>
                      <MenuItem
                        as={Link}
                        to="/all-users-for-check-details"
                        color="black"
                      >
                        All Users
                      </MenuItem>
                      <MenuItem
                        as={Link}
                        to="/all-contact-details-for-admin-check"
                        color="black"
                      >
                        All Contact Data
                      </MenuItem>
                      {isAdmin ? (
                        <MenuItem
                          as={Link}
                          to="/handle-create-jobs-by-a-all-id"
                          color="black"
                        >
                          Manage Jobs
                        </MenuItem>
                      ) : null}
                      {isAdmin ? (
                        <MenuItem
                          as={Link}
                          to="/get-data-random-apply-without-any-job-reference"
                          color="black"
                        >
                          User Without job reference
                        </MenuItem>
                      ) : null}

                      {isAdmin ? (
                        <MenuItem
                          as={Link}
                          to="/register-admin-for-post-jobs"
                          color="black"
                        >
                          Add Admin
                        </MenuItem>
                      ) : null}
                    </MenuList>
                  </Box>
                </Menu>
              </span>
              <span className="nav-item">
                <Menu>
                  <Box>
                    {isAdmin ? (
                      <MenuButton
                        colorScheme="pink"
                        as={Button}
                        rightIcon={<IoMdArrowDropdown />}
                      >
                        Freelancer Admin Panel
                      </MenuButton>
                    ) : null}
                    <MenuList>
                      {isAdmin ? (
                        <MenuItem
                          as={Link}
                          to="/post-freelancer-projects"
                          color="black"
                        >
                          Post Freelancer Projects
                        </MenuItem>
                      ) : null}
                      {isAdmin ? (
                        <MenuItem
                          as={Link}
                          to="/get-freelaner-apply-data"
                          color="black"
                        >
                          Get freelancer Projects Details
                        </MenuItem>
                      ) : null}
                      {isAdmin ? (
                        <MenuItem
                          as={Link}
                          to="/manage-freelancing-projects-post"
                          color="black"
                        >
                          Manage Projects
                        </MenuItem>
                      ) : null}
                    </MenuList>
                  </Box>
                </Menu>
              </span>
            </span>
            <form className="d-flex" role="search">
              <span className="nav-item">
                <span className="nav-link">
                  {localStorage.getItem("adminInfo") ? (
                    <Button
                      onClick={logoutHandler}
                      marginRight={2}
                      colorScheme="pink"
                      variant="outline"
                    >
                      Logout
                    </Button>
                  ) : (
                    <Button
                      as={Link}
                      to="/login"
                      marginRight={2}
                      colorScheme="pink"
                      variant="outline"
                    >
                      Login
                    </Button>
                  )}
                </span>
              </span>
              <span className="nav-item">
                <span className="nav-link">
                  {localStorage.getItem("adminInfo") ? (
                    <Button
                      marginRight={2}
                      colorScheme="pink"
                      as={Link}
                      to="/create-jobs-for-job-seekers-get-the-job-hvevcejhvce-vrev"
                    >
                      Post a Job
                    </Button>
                  ) : null}
                </span>
              </span>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
