import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import "./../JobPost/Jobpost.css";
import { Link } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import Navbar from "../Header/Navbar";
import { useNavigate } from "react-router-dom";

const ApplyRandom = (props) => {
  const [init, setInit] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [country, setCountry] = useState("");
  const [countryAply, setCountryAply] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const SubmitHandler = async () => {
    if (
      !name ||
      !address ||
      !mobile ||
      !email ||
      !jobtitle ||
      !country ||
      !countryAply ||
      !pdfFile
    ) {
      toast({
        title: "Please fill all the fields.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("address", address);
      formData.append("mobile", mobile);
      formData.append("email", email);
      formData.append("jobtitle", jobtitle);
      formData.append("country", country);
      formData.append("countryAply", countryAply);
      formData.append("pdfFile", pdfFile);

      const response = await axios.post(
        `${mod.api_url}/JobAply/aply_job`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast({
        title: "Your Query Sent Successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/");
      setName("");
      setAddress("");
      setMobile("");
      setEmail("");
      setJobtitle("");
      setCountry("");
      setCountryAply("");
      setPdfFile(null);
    } catch (error) {
      setLoading(false);
      toast({
        title: "You can not apply",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="apply-any">
        <Container centerContent marginBottom={3}>
          <Text
            fontSize="large"
            background="hotpink"
            borderRadius="30px"
            padding={2}
          >
            Apply for Any Job Details
          </Text>
          <Box
            p={3}
            width="100%"
            border="1px solid #e2e8f0"
            borderRadius="10px"
            borderWidth="1px"
          >
            <FormControl id="name" isRequired>
              <FormLabel color="white">Enter Your Name</FormLabel>
              <Input
                placeholder="Enter Your Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                color="white"
              />
            </FormControl>
            <FormControl id="address" isRequired>
              <FormLabel color="white">Enter Your Address</FormLabel>
              <Input
                placeholder="Enter Your address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                color="white"
              />
            </FormControl>
            <FormControl id="country" isRequired>
              <FormLabel color="white">Enter Your Country</FormLabel>
              <Input
                placeholder="Enter Your Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                color="white"
              />
            </FormControl>
            <FormControl id="countryAply" isRequired>
              <FormLabel color="white">Apply for Country</FormLabel>
              <Input
                placeholder="Apply for Country"
                onChange={(e) => setCountryAply(e.target.value)}
                value={countryAply}
                color="white"
              />
            </FormControl>
            <FormControl id="mobile" isRequired>
              <FormLabel color="white">Enter Your Mobile NO</FormLabel>
              <Input
                type="tel"
                placeholder="Enter Your Mobile No"
                padding={1}
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                color="white"
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel color="white">Enter Your Email</FormLabel>
              <Input
                placeholder="Enter Your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                color="white"
              />
            </FormControl>
            <FormControl id="jobtitle" isRequired>
              <FormLabel color="white">Enter Your Job Title</FormLabel>
              <Input
                placeholder="Enter Your Jobtitle"
                onChange={(e) => setJobtitle(e.target.value)}
                value={jobtitle}
                type="text"
                color="white"
              />
            </FormControl>
            <FormControl id="pdffile" isRequired>
              <FormLabel color="white">Upload Your Resume</FormLabel>
              <Input
                placeholder="Upload Your Resume"
                type="file"
                paddingTop={1}
                onChange={(e) => setPdfFile(e.target.files[0])}
                color="white"
              />
            </FormControl>

            <Button
              marginTop={3}
              width="100%"
              colorScheme="pink"
              onClick={SubmitHandler}
            >
              Submit Form
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ApplyRandom;
