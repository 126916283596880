import React from "react";

const Screen8 = () => {
  return (
    <div className="screen3">
      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-6">
          <img
            className="slide-image"
            src="assests/77.jpg"
            style={{ width: "100%", margin: "10px" }}
          />
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <img src="assests/72.jpg" className="img-adjust" />
            </div>
            <div className="col-md-6">
              <img src="assests/73.jpg" className="img-adjust" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img src="assests/74.jpg" className="img-adjust" />
            </div>
            <div className="col-md-6">
              <img src="assests/75.jpg" className="img-adjust" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen8;
