import { useContext, useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import "./../JobPost/Jobpost.css";
import axios from "axios";
import * as mod from "./../../url";
import Navbar from "../Header/Navbar";
import { SelectedItemContext } from "../SelectedItemContext";

const ApplyProject = (props) => {
  const [init, setInit] = useState(false);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [isValid, setIsValid] = useState(true);
  const { selectedItem } = useContext(SelectedItemContext);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SubmitHandler = async () => {
    if (
      !name ||
      !country ||
      !desc ||
      !email ||
      !mobile ||
      !price ||
      !duration
    ) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
      return;
    }

    if (desc.split(" ").length < 20) {
      toast({
        title: "Description must be at least 50 words long.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setIsValid(false);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      const formData = {
        name,
        country,
        desc,
        email,
        mobile,
        price,
        duration,
        projects: selectedItem,
      };
      const response = await axios.post(
        `${mod.api_url}/freelancer/freelancer_create`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response, "freelancer apply project");

      setName("");
      setCountry("");
      setDesc("");
      setEmail("");
      setMobile("");
      setPrice("");
      setDuration("");

      toast({
        title: "Project Bid Successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error Project Biding:", error.response || error);

      toast({
        title: "Project Bid failed due to some issue.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="jobpost">
        <Container centerContent marginBottom={3}>
          <Text fontSize="xx-large" fontFamily="Work sans" color="white">
            Jobs Again Hr & Consultancy{" "}
            <Text
              fontSize="large"
              background="hotpink"
              textAlign="center"
              borderRadius="30px"
            >
              Apply for Freelancer Project
            </Text>
          </Text>
          <Box
            p={3}
            width="100%"
            border="1px solid #e2e8f0"
            borderRadius="10px"
            borderWidth="1px"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Enter Name</FormLabel>
              <Input
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </FormControl>
            <FormControl id="price" isRequired>
              <FormLabel>Enter Your Bid Amount</FormLabel>
              <Input
                placeholder="Enter Your Bid Amount"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </FormControl>
            <FormControl id="phone" isRequired>
              <FormLabel>Enter Phone No</FormLabel>
              <Input
                type="tel"
                placeholder="Enter Phone No"
                padding={1}
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Enter Your Email </FormLabel>
              <Input
                placeholder="Enter Your Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
              />
            </FormControl>
            <FormControl id="country" isRequired>
              <FormLabel>Enter Your Country </FormLabel>
              <Input
                placeholder="Enter Your Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              />
            </FormControl>
            <FormControl id="duration" isRequired>
              <FormLabel>Enter Your Time Line </FormLabel>
              <Input
                placeholder="Enter Your Time Line"
                onChange={(e) => setDuration(e.target.value)}
                value={duration}
              />
            </FormControl>
            <FormControl id="desc" isRequired>
              <FormLabel>Enter Description</FormLabel>
              <Textarea
                placeholder="Enter Job Skills"
                onChange={(e) => {
                  setDesc(e.target.value);
                  setIsValid(true);
                }}
                value={desc}
                borderColor={isValid ? "gray.200" : "red.500"}
                _hover={{ borderColor: isValid ? "gray.300" : "red.500" }}
                _focus={{ borderColor: isValid ? "blue.500" : "red.500" }}
              />
            </FormControl>
            {!isValid && (
              <p style={{ color: "red" }}>
                Description must be at least 50 words long.
              </p>
            )}
            <Button
              marginTop={3}
              width="100%"
              colorScheme="pink"
              onClick={SubmitHandler}
              isLoading={loading}
            >
              Submit
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ApplyProject;
