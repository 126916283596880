import { Box, Text } from "@chakra-ui/react";
import React from "react";
import styled from "@emotion/styled";
import { FaArrowRight } from "react-icons/fa";

const Screen4 = () => {
  const Button = styled.button`
    padding: 10px;
    background-color: hotpink;
    font-size: 24px;
    border-radius: 4px;
    height: 55px;
    color: black;
    font-weight: bold;
    &:hover {
      color: white;
    }
  `;

  return (
    <>
      <div className="screen2">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <Text fontWeight="700" fontSize="xxx-large" textAlign="center">
              Tap into a
            </Text>
            <Text
              fontWeight="900"
              fontSize="xxx-large"
              textAlign="center"
              color="hotpink"
            >
              global talent network
            </Text>
            <div className="row">
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Post your job
                </Text>
                <Text fontSize="small" margin="10px">
                  Discover reliable professionals by exploring their portfolios
                  and immersing yourself in the feedback shared on their
                  profiles.
                </Text>
              </div>
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Choose Us
                </Text>
                <Text fontSize="small" margin="10px">
                  Get quick, no-obligation quotes from skilled freelancers. 80%
                  of jobs receive bids within 60 seconds. Your idea is just
                  moments from reality.
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Pay safely
                </Text>
                <Text fontSize="small" margin="10px">
                  Discover reliable professionals by exploring their portfolios
                  and immersing yourself in the feedback shared on their
                  profiles.
                </Text>
              </div>
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  We're here to help
                </Text>
                <Text fontSize="small" margin="10px">
                  Get quick, no-obligation quotes from skilled freelancers. 80%
                  of jobs receive bids within 60 seconds. Your idea is just
                  moments from reality.
                </Text>
              </div>
            </div>
            <Text fontWeight="800" fontSize="xx-large">
              Create the future.
            </Text>
            <Button
              fontSize="xx-large"
              display="inline-flex"
              alignItems="center"
              color="hotpink"
              fontFamily="cursive"
            >
              Get Started Now
              <FaArrowRight
                style={{
                  marginLeft: "20px",
                  display: "inline-flex",
                }}
              />
            </Button>
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <img
              src="assests/88.jpg"
              className="slide-image"
              style={{
                width: "100%",
                height: "100%",
                margin: "10px",
                marginTop: "20px",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen4;
