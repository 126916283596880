import { useContext, useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import "./../JobPost/Jobpost.css";
import { SelectedItemContext } from "../SelectedItemContext";
import { confirmAlert } from "react-confirm-alert";

const ManageFreelancingProject = (props) => {
  const [init, setInit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [selectedImage, setSelectedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { setSelectedItem } = useContext(SelectedItemContext);
  const [freelancerCount, setFreelancerCount] = useState(0);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/project/get_projects`);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs backend issue",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const HandleDelete = async (id) => {
    console.log("Deleting file with ID:", id);
    confirmAlert({
      title: "Edit Job Post",
      message: "Are you sure you want to edit this transaction?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `${mod.api_url}/project/delete_projects/${id}`
              );
              if (response.status === 200) {
                toast({
                  title: "File deleted successfully",
                  status: "success",
                  duration: 1000,
                  isClosable: true,
                  position: "top-right",
                });
                fetchData();
              } else {
                toast({
                  title: "Failed to delete file",
                  status: "error",
                  duration: 1000,
                  isClosable: true,
                  position: "top-right",
                });
              }
            } catch (error) {
              console.error("Error Delete transaction:", error);
              toast({
                title: "Facing issue on Delete Job",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top-right",
              });
            }
          },
        },
        {
          label: "No",
          onClick: () =>
            toast({
              title: "Delete cancelled",
              status: "info",
              duration: 1000,
              isClosable: true,
              position: "top-right",
            }),
        },
      ],
    });
  };

  const freelancerFetchData = async () => {
    try {
      const response = await axios.get(
        `${mod.api_url}/freelancer/get_freelancers`
      );
      setFreelancerCount(response.data.length);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs backend issue",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchData();
    freelancerFetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter((item) => {
        const country = item.country ? item.country.toLowerCase() : "";
        const skils = item.skils ? item.skils.toLowerCase() : "";
        const search = searchTerm.toLowerCase();
        return country.includes(search) || skils.includes(search);
      });
      setFilteredData(filtered);
    }
  }, [searchTerm, data]);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const handleButtonClickK = (item) => {
    setSelectedItem(item);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />

      <div className="jobget">
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          bg="rgb(35, 35, 35)"
          zIndex="1000"
        >
          <Navbar />
        </Box>
        <Box width="100%" height="auto">
          <Container>
            <Text fontSize="xx-large" fontFamily="lemon" fontWeight="700">
              All Freelancer Projects
            </Text>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-10">
                <Input
                  className="form-control"
                  type="search"
                  placeholder="Search Hear"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ color: "black" }}
                  _placeholder={{ color: "white" }}
                />
              </div>
              <div className="col-2">
                <Button
                  colorScheme="pink"
                  type="button"
                  onClick={() => setSearchTerm("")}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Container>

          {currentItems && currentItems.length > 0 ? (
            currentItems.map((item) => (
              <Card
                key={item._id}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                margin="10px"
              >
                <Stack>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-6">
                        <Heading size="md" textAlign="left">
                          <strong>Project Name :</strong>
                          {item.prjectname}
                        </Heading>
                        <Text textAlign="left">
                          <strong>Project Price:</strong> {item.price}
                        </Text>
                        <Text textAlign="left">
                          <strong>Project Location:</strong> {item.country}
                        </Text>
                        <Text textAlign="left">
                          <strong>Project Duration :</strong> {item.duration}
                        </Text>
                        <Text textAlign="left">
                          <strong>Post Date :</strong>{" "}
                          {item.createdAt.slice(0, 10)}
                        </Text>
                        <Text textAlign="left">
                          <strong>Applications:</strong> {freelancerCount}
                        </Text>
                      </div>
                      <div className="col-md-6">
                        <Text textAlign="left">
                          <strong>Job Discription:</strong>{" "}
                          {item.desc.slice(0, 300)}
                        </Text>
                        <Button
                          colorScheme="red"
                          width="100%"
                          onClick={() => HandleDelete(item._id)}
                        >
                          Delete Project
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Stack>
              </Card>
            ))
          ) : (
            <Text>No Freelancer Project available</Text>
          )}
        </Box>
        <Box display="flex" justifyContent="center" mt={4} marginBottom={3}>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index}
              onClick={() => paginate(index + 1)}
              colorScheme="pink"
              mx={1}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </Button>
          ))}
        </Box>
      </div>
    </>
  );
};

export default ManageFreelancingProject;
