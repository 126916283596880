import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import Navbar from "../Header/Navbar";
import "./../JobPost/Jobpost.css";
import * as mod from "./../../url";

const PostProject = (props) => {
  const [init, setInit] = useState(false);
  const [prjectname, setProjectname] = useState("");
  const [price, setPrice] = useState("");
  const [country, setCountry] = useState("");
  const [duration, setDuration] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const toast = useToast();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SubmitHandler = async () => {
    if (!prjectname || !price || !country || !duration || !desc) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
      return;
    }

    if (desc.split(" ").length < 50) {
      toast({
        title: "Description must be at least 50 words long.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setIsValid(false);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      const formData = {
        prjectname,
        price,
        country,
        duration,
        desc,
      };

      const response = await axios.post(
        `${mod.api_url}/project/submit_Project`,
        formData
      );

      console.log(response, "freelancer post project");

      setProjectname("");
      setPrice("");
      setCountry("");
      setDuration("");
      setDesc("");

      toast({
        title: "Project Post Uploaded Successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error uploading job post:", error.response || error);

      toast({
        title: "Project Post failed due to some backend issue.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="jobpost">
        <Container centerContent marginBottom={3}>
          <Text
            fontSize="large"
            background="hotpink"
            textAlign="center"
            borderRadius="30px"
            padding={2}
            marginTop={2}
          >
            Post Freelancer Projects
          </Text>
          <Box
            p={3}
            width="100%"
            border="1px solid #e2e8f0"
            borderRadius="10px"
            borderWidth="1px"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Enter Project Name</FormLabel>
              <Input
                placeholder="Enter Project Name"
                onChange={(e) => setProjectname(e.target.value)}
                value={prjectname}
              />
            </FormControl>
            <FormControl id="price" isRequired>
              <FormLabel>Enter Project Price</FormLabel>
              <Input
                placeholder="Enter Project Price"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </FormControl>
            <FormControl id="country" isRequired>
              <FormLabel>Enter Your Country</FormLabel>
              <Input
                placeholder="Enter Your Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              />
            </FormControl>
            <FormControl id="duration" isRequired>
              <FormLabel>Enter Project Duration</FormLabel>
              <Input
                placeholder="Enter Project Duration"
                onChange={(e) => setDuration(e.target.value)}
                value={duration}
                type="text"
              />
            </FormControl>
            <FormControl id="description" isRequired>
              <FormLabel>Enter Project Description</FormLabel>
              <Textarea
                placeholder="Enter Project Description"
                onChange={(e) => {
                  setDesc(e.target.value);
                  setIsValid(true);
                }}
                borderColor={isValid ? "gray.200" : "red.500"}
                _hover={{ borderColor: isValid ? "gray.300" : "red.500" }}
                _focus={{ borderColor: isValid ? "blue.500" : "red.500" }}
                value={desc}
              />
            </FormControl>
            {!isValid && (
              <p style={{ color: "red" }}>
                Description must be at least 50 words long.
              </p>
            )}

            <Button
              marginTop={3}
              width="100%"
              colorScheme="pink"
              onClick={SubmitHandler}
              isLoading={loading}
            >
              Post Job
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default PostProject;
