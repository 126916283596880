import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const Ourteam = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to SHARDA ASSOCIATES
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Meet Our Team
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          SHARDA ASSOCIATES, our strength lies in the expertise and dedication
          of our team. We are a group of passionate HR professionals committed
          to helping job seekers find their ideal roles and businesses secure
          top talent. Our team's diverse backgrounds and extensive experience in
          the industry enable us to provide exceptional service and innovative
          solutions.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Our Values
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong> Expertise:</strong> We bring a wealth of knowledge and
          experience to every client engagement, ensuring that we understand
          their unique needs and challenges.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Dedication:</strong> We are committed to providing
          personalized support and guidance to every job seeker and employer we
          work with, going above and beyond to ensure their success.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong> Integrity:</strong> We operate with honesty, transparency,
          and integrity in all our interactions, building trust and credibility
          with our clients and partners.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Job Placement Services: Our extensive network of employers and
          industry contacts allows us to match job seekers with the right
          opportunities. We work tirelessly to ensure that our candidates find
          positions that align with their skills and aspirations.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Innovation:</strong> We embrace innovation and creativity in
          our approach to HR solutions, constantly seeking new ways to improve
          and enhance our services.
        </Text>

        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          Our Specialized Teams
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Recruitment Team:</strong> Our recruitment team comprises
          seasoned professionals who excel at sourcing, screening, and matching
          candidates with the right opportunities. They leverage their industry
          knowledge and networks to connect job seekers with employers in
          various sectors.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Career Coaching and Counseling Team:</strong> Our career
          coaching and counseling team provides personalized guidance and
          support to job seekers, helping them navigate their career paths with
          confidence. They offer insights, advice, and resources to help
          individuals achieve their professional goals.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>HR Consulting Team:</strong> Our HR consulting team offers
          comprehensive HR solutions to businesses looking to optimize their HR
          processes. From recruitment and onboarding to performance management
          and employee development, they provide strategic guidance and support
          to help organizations build and maintain strong and effective teams.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Join Our Team
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We are always looking for talented individuals to join our growing
          team. If you are passionate about HR and want to make a difference in
          the job market, we would love to hear from you. Explore our current
          job openings and apply today!
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Contact Us
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          For more information about our team or services, please contact us at:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          This expanded content provides a more detailed overview of the values,
          team members, and specialized teams at Jobs Again HR & Consultants
          Private Limited. Adjust any specific details to match your company's
          values and team structure.
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default Ourteam;
